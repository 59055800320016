import { TCF_READY_EVENT } from './privacy/cmp/events';
import executeModule from './utils/execute-module';
import { deferOnLoad } from './utils/defer-onload';
import {
    importIf,
    importOnIdle,
    importOnLazyload,
    isConsentGiven,
    isSubscriber,
} from './utils/import';

const init = async () => {
    /**
     * HIGH PRIORITY imports are the static imports above (initial bundle)
     **/

    /**
     * MEDIUM PRIORITY (dynamic import)
     **/

    window.addEventListener(TCF_READY_EVENT, () => {
        executeModule(() => import('./advertory'));
        executeModule(() => import('./tracking'));
        executeModule(() => import('./tracking/pulse/row-tracking'));
        executeModule(() => import('./tracking/pulse/teaser-tracking'));
    });

    executeModule(() => import('./privacy/cmp/init'));

    executeModule(() => import('./account')).then(() => {
        const headerElement = document.querySelector('.e24-header');

        if (headerElement) {
            executeModule(
                () => import('./components/hovedmenyen'),
                headerElement
            );
        }

        executeModule(() => import('./privacy/privacy-broker'));
    });

    executeModule(() => import('./privacy/data-controller'));
    executeModule(() => import('./utils/lazyload-images'));
    executeModule(() => import('./width-without-scrollbar'));
    executeModule(() => import('./cta-cards/tracking'));
    executeModule(() => import('./aksjelive-stripe/index'));

    const stockStripeElement = document.querySelector(
        '.component_stocks-stripe'
    );

    if (stockStripeElement) {
        executeModule(
            () => import('./components/stocks-stripe'),
            stockStripeElement
        );
    }

    const ferieValutaElement = document.querySelector(
        '.component_ferievaluta-kalkulator'
    );

    if (ferieValutaElement) {
        executeModule(
            () => import('./components/ferievaluta'),
            ferieValutaElement
        );
    }

    /**
     *
     * lazyload uses querySelectorAll, so maybe we can instead do:
     * ```
     * lazyload('[data-component], (entry) => {
     *      const componentName = entry.target.getAttribute('data-component');
     *      import('./components/' + componentName);
     * });
     * ```
     *
     * Though it would cause a dynamic import that webpack cant predict
     * on compile-time, and it would require componentName to be equal
     * to the folder name, so I'm not so sure.
     *
     **/

    /**
     * ON DEMAND (dynamic import when element is visible)
     **/

    importOnLazyload('.touchpoint', () => import('./components/touchpoint'));
    importOnLazyload('.comic', () => import('./components/comic'));

    importOnLazyload(
        '#login-button',
        () => import('./components/account-info')
    );
    importOnLazyload('.carousel', () => import('./components/carousel'));

    importOnLazyload(
        '.component_content-marketing',
        () => import('./ads/components/content')
    );

    importOnLazyload(
        '.component_blink',
        () => import('./advertory/components/blink')
    );
    importOnLazyload(
        '.component_aksjelive-feed',
        () => import('./components/aksjelive/feed')
    );

    importOnLazyload(
        '.component_ledertalentene-tips-widget',
        () => import('./components/ledertalentene/tips-widget')
    );

    importOnLazyload(
        '.salesposter-wrapper',
        () => import('./components/salesposter')
    );
    importOnLazyload('.e24-kolofonen', () => import('./components/kolofonen'));
    importOnLazyload('#livestocks-button', () => import('./pages/livestock'));

    importOnLazyload('.e24-video', () => import('./components/video'));

    /**
     * LOW PRIORITY (defer to document load event)
     **/

    await deferOnLoad();

    importIf(
        [() => isConsentGiven('CMP:advertising')],
        () => import('./brandmetrics')
    );
    importIf(
        [() => isConsentGiven('CMP:marketing'), () => isSubscriber()],
        () => import('./braze')
    );

    /**
     * VERY LOW PRIORITY (idle callback or timeout as polyfill)
     **/

    if (Math.random() >= 0.9) {
        importOnIdle(() => import('./tracking/performance'));
    }
};

init();
