import type { ConsentCategory } from '@schibsted/sourcepoint';
import executeModule, {
    ExecutableModule,
    GetExecutableModule,
} from './execute-module';
import lazyload, { LazyLoadCallback } from './lazyload';

export function importOnLazyload(
    selector: string,
    importCallback: LazyLoadCallback<ExecutableModule>
): void {
    lazyload(selector, async (el) => {
        executeModule(importCallback(el), el);
    });
}

export function importOnIdle(importCallback: GetExecutableModule): void {
    const cb = () => executeModule(importCallback());

    if (window.requestIdleCallback) {
        window.requestIdleCallback(cb, { timeout: 500 });
    } else {
        setTimeout(cb, 200 + Math.random() * 100);
    }
}

export async function isConsentGiven(category: ConsentCategory) {
    const { verifyConsent } = await import('../privacy/cmp/consents');
    return verifyConsent(category);
}

export async function isSubscriber() {
    const { getSubscription, getUser } = await import('../account');

    const userId = (await getUser())?.userId;
    if (!userId) {
        return;
    }

    return (await getSubscription(userId, null))?.hasAccess;
}

export async function importIf(
    predicates: (() => boolean | Promise<boolean>)[],
    importCallback: GetExecutableModule
) {
    try {
        const results = await Promise.all(
            predicates.map((predicate) => predicate())
        );

        const shouldImport = results.every(Boolean);
        if (shouldImport) {
            executeModule(importCallback());
        }

        return shouldImport;
    } catch {
        return false;
    }
}
